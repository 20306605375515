<template>
	<div v-show="componentDoneLoading" class="authPage">
		<self-sign-in
			v-if="step == 1"
			:courses="[course]"
			:sections="sections.value"
			:credentials="credentials.value"
			@done="loginFromSelfSignIn"
			@back="step = 0"
		></self-sign-in>
		<div
			v-if="step == 0"
			class="
				content-top-bar
				d-flex
				justify-content-between
				align-items-center
				bg-white
			"
			:class="{
				'top-bar-fixed p-3': helpers.layout.isMobile(),
				'position-sticky p-4': !helpers.layout.isMobile(),
			}"
		>
			<router-link :to="{ name: 'auth.login' }" class="text-dark d-flex">
				<span class="material-icons-outlined" aria-label="back">
					arrow_back
				</span>
			</router-link>
		</div>
		<div
			v-show="step == 0"
			:class="{
				'pb-104': helpers.layout.isMobile(),
				'height-minus-top-bar': !helpers.layout.isMobile(),
			}"
		>
			<kr-form
				ref="form"
				v-slot="{ meta, rules, data, isSubmitting }"
				:options="formOptions"
				class="needs-validation"
				autocomplete="false"
				novalidate
			>
				<div
					class="mx-auto pt-40"
					:class="{
						'w-100': helpers.layout.isMobile(),
						'w-50 min-h-100': !helpers.layout.isMobile(),
					}"
				>
					<div
						:class="{
							'px-3': helpers.layout.isMobile(),
						}"
					>
						<div v-if="!course" class="w-100 text-center">
							<span
								class="
									pageIcons
									d-inline-flex
									align-items-center
									justify-content-center
								"
							>
								<span class="material-icons-outlined" aria-hidden="true">
									meeting_room
								</span>
							</span>
						</div>
						<template v-else>
							<div class="course-info text-center">
								<h1>
									{{ course.name }}
									{{ course.code ? `( ${course.code} )` : "" }}
								</h1>
							</div>
							<div class="w-100 mb-3 text-center">
								<img
									:class="{ 'w-50': course.coverImage }"
									:src="
										course.coverImage
											? course.coverImage
											: '/img/course-image.png'
									"
									alt=""
								/>
							</div>
							<div>
								<div class="course-info">
									<template v-if="course.description">
										<label class="form-label"> Description </label>
										<p>
											{{ course.description }}
										</p>
									</template>

									<template v-if="course.organisationName">
										<label class="form-label"> School </label>
										<p>
											{{ course.organisationName }}
										</p>
									</template>
									<template v-if="course.courseOwner">
										<label class="form-label"> Instructor </label>
										<p>{{ course.courseOwner }}</p>
									</template>
								</div>
							</div>
						</template>
					</div>

					<div
						class="pt-40"
						:class="{
							'px-3': helpers.layout.isMobile(),
						}"
					>
						<template v-if="!course">
							<div>
								<div>
									<kr-form-i
										v-slot="{ field, errors }"
										v-model="data.model.courseID"
										name="courseID"
										tooltip-message="This is the unique 8 number identifier given by your course instructor."
									>
										<!-- :rules="rules('courseID')" -->
										<label class="form-label d-flex" for="courseID_input">
											Enter Course ID
											<span class="visible-hidden"> Required </span>
											<span class="text-danger" aria-hidden="true">*</span>
											<!-- <span
												:class="{ isMobile: helpers.layout.isMobile() }"
												tooltip="This is the unique 8 number identifier given by your course instructor. "
												class="material-icons-outlined"
												aria-label=""
											>
												info
											</span> -->
										</label>
										<input
											id="courseID_input"
											type="text"
											class="form-control"
											:class="{ 'is-invalid': errors[0] }"
											v-bind="field"
											aria-describedby="courseID_error"
											aria-required="true"
										/>
									</kr-form-i>
								</div>
							</div>
							<div
								v-if="store.getters.expressSignInUrls.length > 0"
								class="mt-3"
							>
								<figure>
									<figcaption>
										<b> Recent Courses </b>
									</figcaption>
									<ul id="recentCoursesLink">
										<li
											v-for="(a, idx) in store.getters.expressSignInUrls"
											:key="idx"
										>
											<a
												class="fw-normal"
												href="#"
												@click="updateCourse(a.urlCode)"
											>
												{{ a.name }}
											</a>
										</li>
									</ul>
								</figure>
							</div>
						</template>
						<template v-else>
							<div v-if="course.ssoGoogle || course.ssoMicrosoft" class="pb-40">
								<template v-if="course.ssoGoogle">
									<button
										class="
											w-100
											btn btn-outline-dark
											w-icon
											rounded-pill
											justify-content-start
										"
										:class="{ 'mb-3': course.ssoMicrosoft }"
										@click="processGoogleLogin()"
									>
										<span
											class="
												round-icon
												d-flex
												align-items-center
												justify-content-center
											"
										>
											<img src="/img/google.png" alt="Google Logo" />
										</span>
										<span class="button-with-left-icon">
											Sign In with Google
										</span>
									</button>
								</template>
								<template v-if="course.ssoMicrosoft">
									<button
										class="
											w-100
											btn btn-outline-dark
											w-icon
											rounded-pill
											justify-content-start
										"
										@click="processMicrosoftLogin()"
									>
										<span
											class="
												round-icon
												d-flex
												align-items-center
												justify-content-center
											"
										>
											<img src="/img/microsoft-icon.png" alt="Microsoft Logo" />
										</span>
										<span class="button-with-left-icon">
											Sign In with Microsoft
										</span>
									</button>
								</template>
							</div>

							<template v-if="!course.anonymous && !isEmailTested">
								<div>
									<div class="mb-4">
										<kr-form-i
											v-slot="{ field, errors }"
											v-model="data.model.email"
											name="email"
											:rules="rules('email')"
											tooltip-message="This is the valid email address associated with your institution."
										>
											<label class="form-label" for="email_input">
												Email
												<span class="visible-hidden">Required</span>
												<span class="text-danger" aria-hidden="true">*</span>
												<!-- <span
													:class="{ isMobile: helpers.layout.isMobile() }"
													tooltip="This is the valid email address associated with your institution."
													class="material-icons-outlined"
													aria-label=""
												>
													info
												</span> -->
											</label>
											<input
												id="email_input"
												type="text"
												class="form-control"
												v-bind="field"
												:class="{ 'is-invalid': errors[0] }"
												aria-required="true"
											/>
											<div
												v-if="course.allowedDomainsDescription"
												class="form-text"
											>
												{{ course.allowedDomainsDescription }}
											</div>
										</kr-form-i>
									</div>
								</div>
							</template>
							<template v-else>
								<div>
									<div
										v-if="!course.anonymous && hasExistingAccount"
										class="alert alert-success text-white d-flex mb-2"
										role="alert"
										aria-live="assertive"
										aria-atomic="true"
									>
										<span class="material-icons-outlined me-1" aria-label="">
											error_outline
										</span>
										It looks like you already have an account with us, please
										sign in below to join this course.
									</div>

									<div v-if="!course.anonymous" class="mb-4">
										<div class="form-group">
											<label for="staticEmail" class="sr-only">Email</label>
											<input
												id="staticEmail"
												type="text"
												readonly
												class="form-control-plaintext"
												:value="data.model.email"
											/>
											<a href="#" @click.prevent="resetEmail"> Change Email </a>
										</div>
									</div>

									<div
										v-if="!course.anonymous && hasExistingAccount"
										class="mb-4"
									>
										<kr-form-i
											v-slot="{ field, errors }"
											v-model="data.model.password"
											name="password"
											:rules="rules('password')"
											tooltip-message="Please enter a unique password - It should be at least 12 characters and contain a combination of uppercase and lowercase letters, numbers, and symbols."
										>
											<label for="password_input" class="form-label">
												Password
												<span class="visible-hidden">Required</span>
												<span class="text-danger" aria-hidden="true">*</span>
												<!-- <span
													:class="{ isMobile: helpers.layout.isMobile() }"
													tooltip="Please enter a unique password - It should be at least 12 characters and contain a combination of uppercase and lowercase letters, numbers, and symbols."
													class="material-icons-outlined"
													aria-label=""
												>
													info
												</span> -->
											</label>
											<input
												id="password_input"
												type="password"
												class="form-control"
												v-bind="field"
												:class="{ 'is-invalid': errors[0] }"
												aria-required="true"
											/>
										</kr-form-i>
									</div>
									<div v-if="!hasExistingAccount" class="mb-4">
										<kr-form-i
											v-slot="{ field, errors }"
											v-model="data.model.name"
											name="name"
											:rules="rules('name')"
											tooltip-message="This is your full name - First Name and Last Name."
										>
											<label class="form-label" for="name_input">
												Full name
												<span class="visible-hidden">Required</span>
												<span class="text-danger" aria-hidden="true">*</span>
												<!-- <span
													:class="{ isMobile: helpers.layout.isMobile() }"
													tooltip="This is your full name - First Name and Last Name."
													class="material-icons-outlined"
													aria-label=""
												>
													info
												</span> -->
											</label>
											<input
												id="name_input"
												type="text"
												class="form-control"
												v-bind="field"
												:class="{ 'is-invalid': errors[0] }"
												aria-required="true"
											/>
										</kr-form-i>
									</div>

									<kr-change-password
										v-if="!course.anonymous && !hasExistingAccount"
										:data="data"
										:class="{ 'mb-4': course.secretPinRequired }"
										:rules="rules"
										@canSend="processCanSendChangePassword"
									></kr-change-password>

									<div v-if="course.secretPinRequired">
										<kr-form-i
											v-slot="{ field, errors }"
											v-model="data.model.secretPin"
											name="secretPin"
											:rules="rules('secretPin')"
										>
											<label for="secretPin_input">
												<span>
													Secret PIN
													<span class="visible-hidden">Required</span>
													<span class="text-danger" aria-hidden="true">*</span>
												</span>

												<button
													class="btn btn-outline-light iconOnly"
													data-bs-toggle="modal"
													data-bs-target="#secretPin"
													aria-label="secret pin info"
												>
													<span
														class="material-icons-outlined"
														aria-hidden="true"
													>
														info
													</span>
												</button>
											</label>
											<input
												id="secretPin_input"
												type="text"
												class="form-control"
												v-bind="field"
												:class="{ 'is-invalid': errors[0] }"
												aria-required="true"
											/>
											<!-- <pin-code> </pin-code> -->
											<!-- <input
											type="text"
											class="form-control"
											v-bind="field"
											:class="{ 'is-invalid': errors[0] }"
										/> -->
										</kr-form-i>
									</div>
								</div>
							</template>
						</template>
					</div>
				</div>
				<div
					:class="{
						'position-fixed bottom-0 start-0 w-100': helpers.layout.isMobile(),
						'position-sticky bottom-0 pt-30': !helpers.layout.isMobile(),
					}"
				>
					<div
						v-if="helpers.layout.isMobile()"
						class="custom-btn-gradient bg-gradient"
					/>
					<div
						v-if="route.query.error"
						class="alert alert-danger text-white d-flex align-items-center"
						role="alert"
						aria-live="assertive"
						aria-atomic="true"
					>
						<span class="material-icons-outlined me-1" aria-label="">
							error_outline
						</span>
						{{ route.query.message }}
					</div>
					<button
						v-if="!course"
						type="submit"
						class="w-100 btn btn-primary"
						:class="{ disabled: !data.model.courseID }"
						data-bs-toggle="tooltip"
						title="Please Enter Course ID"
						@click="data.model.courseID ? getCourse() : ''"
					>
						<template v-if="isSubmitting">
							<span
								class="spinner-border spinner-border-sm me-8px"
								role="status"
								aria-hidden="true"
							></span>
							Loading...
						</template>
						<template v-else> Next </template>
					</button>
					<button
						v-else-if="!course.anonymous && !isEmailTested"
						type="submit"
						class="w-100 btn btn-primary"
						:disabled="!meta.valid || isSubmitting"
						@click="verifyCourseEmail"
					>
						<template v-if="isSubmitting">
							<span
								class="spinner-border spinner-border-sm me-8px"
								role="status"
								aria-hidden="true"
							></span>
							Loading...
						</template>
						<template v-else> Next </template>
					</button>
					<button
						v-else-if="!hasExistingAccount"
						type="submit"
						class="w-100 btn btn-primary"
						:disabled="
							!meta.valid ||
							isSubmitting ||
							(!course.anonymous && !canSendChangePassword)
						"
						@click="register"
					>
						<template v-if="isSubmitting">
							<span
								class="spinner-border spinner-border-sm me-8px"
								role="status"
								aria-hidden="true"
							></span>
							Loading...
						</template>
						<template v-else> Join Course </template>
					</button>
					<button
						v-else
						type="submit"
						class="w-100 btn btn-primary"
						:disabled="!meta.valid || isSubmitting"
						@click="verifyCourseLogin"
					>
						<template v-if="isSubmitting">
							<span
								class="spinner-border spinner-border-sm me-8px"
								role="status"
								aria-hidden="true"
							></span>
							Loading...
						</template>
						<template v-else> Sign In </template>
					</button>
				</div>
			</kr-form>
		</div>

		<div
			id="secretPin"
			class="modal fade"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			tabindex="-1"
			:class="{ isMobileModal: helpers.layout.isMobile() }"
		>
			<div
				class="modal-dialog"
				:class="{
					'modal-dialog-scrollable': helpers.layout.isMobile(),
					'modal-dialog-centered': !helpers.layout.isMobile(),
					'modal-dialog-flex-end': helpers.layout.isMobile(),
				}"
			>
				<div class="modal-content">
					<div class="modal-header border-0">
						<button
							class="btn btn-outline-light iconOnly"
							data-bs-dismiss="modal"
							aria-label="close modal"
						>
							<span class="material-icons-outlined" aria-hidden="true">
								arrow_back
							</span>
						</button>
					</div>
					<div class="modal-body announcement-modal">
						<h4 class="mb-1">What is</h4>
						<h2 class="mb-3">Secret PIN</h2>
						<p>
							The secret pin method is only available via temporary URL student
							registrations. If your instructor turned this option on for added
							security, you will need the secret pin provided by your instructor
							to access this course.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div
			id="googleAuthFail"
			class="modal fade"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			tabindex="-1"
			:class="{ isMobileModal: helpers.layout.isMobile() }"
		>
			<div
				class="modal-dialog"
				:class="{
					'modal-dialog-scrollable': helpers.layout.isMobile(),
					'modal-dialog-centered': !helpers.layout.isMobile(),
					'modal-dialog-flex-end': helpers.layout.isMobile(),
				}"
			>
				<div class="modal-content">
					<div class="modal-header border-0">
						<button
							class="btn btn-outline-light iconOnly"
							data-bs-dismiss="modal"
							aria-label="close modal"
						>
							<span class="material-icons-outlined" aria-hidden="true">
								arrow_back
							</span>
						</button>
					</div>
					<div class="modal-body announcement-modal">
						<div class="header-icon mb-3">
							<img src="/img/google.png" alt="Google Logo" />
						</div>

						<h2 class="mb-3">Authentication Failed</h2>
					</div>
					<div class="modal-footer p-0 border-0">
						<div class="m-0 w-100">
							<button class="w-100 btn btn-primary">Try Again</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		id="2faModal"
		class="modal fade"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
		tabindex="-1"
		:class="{ isMobileModal: helpers.layout.isMobile() }"
	>
		<div
			class="modal-dialog"
			:class="{
				'modal-dialog-scrollable': helpers.layout.isMobile(),
				'modal-dialog-centered': !helpers.layout.isMobile(),
				'd-flex align-items-end': helpers.layout.isMobile(),
			}"
		>
			<div class="modal-content">
				<div class="modal-header border-0">
					<button
						class="btn btn-outline-light iconOnly"
						data-bs-dismiss="modal"
						aria-label="close modal"
					>
						<span class="material-icons-outlined" aria-hidden="true">
							arrow_back
						</span>
					</button>
				</div>
				<div class="modal-body announcement-modal">
					<div class="header-icon mb-3">
						<div class="w-100 text-center mb-4">
							<span
								class="
									pageIcons
									d-inline-flex
									align-items-center
									justify-content-center
								"
							>
								<span class="material-icons-outlined" aria-hidden="true">
									lock
								</span>
							</span>
						</div>
					</div>

					<h2 class="mb-3 text-center">Almost there...</h2>

					<pin-code
						v-if="form"
						v-model="form.model.pin"
						@change="form.setFieldError('pin')"
					>
					</pin-code>
					<p v-if="form" class="text-center">
						A 6-digit one-time pin (OTP) has been sent via email to
						{{ form.model.email }}. Enter it above to verify your identity and
						continue to your account.<br />
						Click
						<template v-if="!otpTimeout"
							><a href="#" @click.prevent="resend2FA"> here </a></template
						><template v-else>here</template> to resend OTP.
						<template v-if="otpResent"><br />OTP has been resent</template>
					</p>
				</div>

				<div class="modal-footer p-0 border-0">
					<div class="m-0 w-100">
						<div
							v-if="form && form.errors.pin"
							class="alert alert-danger text-white d-flex align-items-center"
							role="alert"
							aria-live="assertive"
							aria-atomic="true"
						>
							<span class="material-icons-outlined me-1" aria-label="">
								error_outline
							</span>
							{{ form.errors.pin }}
						</div>
						<button
							v-if="form && form.model.pin"
							class="w-100 btn btn-primary"
							:disabled="form.isSubmitting || form.model.pin.length != 6"
							@click="login2FA()"
						>
							<template v-if="form.isSubmitting">
								<span
									class="spinner-border spinner-border-sm me-8px"
									role="status"
									aria-hidden="true"
								></span>
								Loading...
							</template>
							<template v-else> Verify </template>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useStore } from "vuex";
import KrChangePassword from "../../components/forms/components/kr-change-password.vue";
import { ref, reactive, onMounted, inject, nextTick, toRefs } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { Modal } from "bootstrap";

export default {
	components: {
		KrChangePassword,
		"self-sign-in": require(`./partials/partial.choose-section-team`).default,
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const $api = inject("$api");
		const auth = inject("auth");
		const _ = inject("_");
		const helpers = inject("helpers");
		const hasExistingAccount = ref(false);
		const isEmailTested = ref(false);
		let otpResent = ref(false);
		let otpTimeout = ref(false);
		let faModal = ref(null);
		const form = ref(null);
		const formOptions = ref({
			fields: {
				courseID: { value: "", rules: "courseID|required" },
			},
		});
		const step = ref(0);
		let sections = toRefs({});
		let credentials = toRefs({});
		let canSendChangePassword = ref(false);
		const processCanSendChangePassword = (bool) => {
			canSendChangePassword.value = bool;
		};
		const course = ref(false);
		const componentDoneLoading = ref(true);

		const processBackButton = () => {
			if (course.value == false) {
				router.push({ name: "auth.login" });
			} else {
				course.value = false;
				formOptions.value = {
					fields: {
						courseID: { value: "", rules: "courseID|required" },
					},
				};
			}
		};
		const processFormFields = () => {
			let email = form.value.values.email;
			if (course.value) {
				if (course.value.anonymous) {
					if (course.value.secretPinRequired) {
						formOptions.value = {
							fields: {
								name: { rules: "expressName|required" },
								secretPin: { rules: "secretPin|required" },
							},
						};
					} else {
						formOptions.value = {
							fields: {
								name: { rules: "expressName|required" },
							},
						};
					}
				} else {
					if (!isEmailTested.value) {
						formOptions.value = {
							fields: {
								email: { value: "", rules: "email|required" },
							},
						};
					} else if (!hasExistingAccount.value) {
						formOptions.value = {
							fields: {
								name: { rules: "expressName|required" },
								email: { rules: "email|required", default: email },
								password: { value: "", rules: "password|required" },
								password_confirmation: {
									value: "",
									rules: "password_confirmation|required",
								},
								secretPin: { rules: "secretPin|required" },
								loginToken: { value: "", rules: "" },
							},
						};
					} else {
						formOptions.value = {
							fields: {
								email: { rules: "email|required", default: email },
								password: { rules: "password|required" },
								pin: { rules: "required" },
								loginToken: { value: "", rules: "required" },
							},
						};
					}
				}
			} else {
				formOptions.value = {
					fields: {
						courseID: { value: "", rules: "courseID|required" },
					},
				};
			}
		};
		const getCourse = () => {
			form.value.handleSubmit((values) => {
				return $api.auth
					.getCourse(values.courseID)
					.then(function (response) {
						course.value = response.data.data;
						processFormFields();
						router.push({
							name: "auth.login.express.course.cnamed",
							params: { courseID: values.courseID },
							query: route.query,
						});
						componentDoneLoading.value = true;
					})
					.catch(function (error) {
						form.value.setErrors({
							courseID: ["Course ID not found"],
						});
						componentDoneLoading.value = true;
					});
			})();
		};
		const register = () => {
			form.value.handleSubmit((values) => {
				let payload = {};
				if (course.value.anonymous) {
					payload.name = form.value.values.name;
					if (course.value.secretPinRequired) {
						payload.secretPin = form.value.values.secretPin;
					}
				} else {
					payload.name = form.value.values.name;
					payload.email = form.value.values.email;
					payload.password = form.value.values.password;
				}
				return $api.auth
					.registerCourse(payload, course.value.urlCode, course.value.url)
					.then(function (response) {
						/*if (!response.data.data.user.is2FAEnabled) {
							auth.login(response.data);
						} else {
							form.value.setFieldValue(
								"loginToken",
								response.data.data.user.loginToken
							);
							faModal.value.show();
						}*/
						auth.addExpressSignInUrl(course.value);
						if (response.data.meta.selfAssignment) {
							sections.value = response.data.meta.sections;
							credentials.value = response.data;
							step.value = 1;
						} else {
							auth.login(response.data);
						}
						//login(course.value.url, response.data.data.token);
					})
					.catch(function (errors) {
						console.log(errors);
						if (course.value.anonymous) {
							if (course.value.secretPinRequired) {
								form.value.setErrors({
									secretPin: ["Please re-enter your pin"],
								});
							} else {
								form.value.setErrors({
									name: ["Something went wrong, please try again later"],
								});
							}
						} else {
							form.value.setErrors(_.get(errors, "response.data.errors", {}));
						}
					});
			})();
		};
		const loginFromSelfSignIn = () => {
			auth.login(credentials.value);
		};
		const login = (apiURL, otp) => {
			form.value.handleSubmit((values) => {
				return $api.auth
					.login(
						{ loginToken: otp, remember: form.value.values.rememberMe },
						apiURL
					)
					.then(function (response) {
						if (!response.data.is2FAEnabled) {
							auth.login(response.data);
						} else {
							form.value.setFieldValue("loginToken", response.data.loginToken);
							faModal.value.show();
						}
					});
			})();
		};
		const verifyCourseEmail = () => {
			form.value.handleSubmit((values) => {
				let payload = {};
				payload.email = values.email;
				return $api.auth
					.verifyCourseEmail(payload, course.value.urlCode, course.value.url)
					.then(function (response) {
						isEmailTested.value = true;
						hasExistingAccount.value = false;
						processFormFields();
						nextTick();
						form.value.setFieldValue("email", payload.email);
					})
					.catch(function (errors) {
						if (_.get(errors, "response.status") === 422) {
							if (
								_.get(errors, "response.data.errors.email") ==
								"The email has already been taken."
							) {
								hasExistingAccount.value = true;
								isEmailTested.value = true;
								processFormFields();
								nextTick();
								form.value.setFieldValue("email", payload.email);
							} else {
								form.value.setErrors({
									email: [_.get(errors, "response.data.errors.email.0")],
								});
							}
						}
					});
			})();
		};
		const verifyCourseLogin = () => {
			form.value.handleSubmit((values) => {
				let payload = {};
				payload.identity = form.value.values.email;
				payload.password = values.password;
				return $api.auth
					.verifyCourse(payload, course.value.urlCode, course.value.url)
					.then(function (response) {
						if (_.get(response, "data.data.user")) {
							form.value.setFieldValue(
								"loginToken",
								response.data.data.user.loginToken
							);
							auth.addExpressSignInUrl(course.value);
							login(course.value.url, response.data.data.user.loginToken);
						}
					})
					.catch(function (errors) {
						if (_.get(errors, "response.status") === 423) {
							form.value.setErrors({
								password: [_.get(errors, "response.data.message")],
							});
						} else {
							form.value.setErrors({
								password: ["Please re-enter your password"],
							});
						}
					});
			})();
		};
		const login2FA = () => {
			form.value.handleSubmit((values) => {
				return $api.auth
					.login2FA(
						{
							loginToken: form.value.values.loginToken,
							pin: form.value.values.pin,
							identity: form.value.values.email,
							remember: form.value.values.rememberMe,
						},
						course.value.url
					)
					.then(function (response) {
						auth.login(response.data);
					})
					.catch(function (errors) {
						form.value.setErrors({
							pin: [errors.response.data.message],
						});
					});
			})();
		};
		const resend2FA = () => {
			otpTimeout.value = true;
			form.value.handleSubmit((values) => {
				return $api.auth
					.resend2FA(
						{ loginToken: form.value.values.loginToken },
						course.value.url
					)
					.then(function (response) {
						otpResent.value = true;
						setTimeout(function () {
							otpTimeout.value = false;
						}, 15000);
					})
					.catch(function (errors) {
						otpTimeout.value = false;
					});
			})();
		};
		const processGoogleLogin = () => {
			$api.auth.googleRedirect(course.value.urlCode).then(function (response) {
				window.location.replace(response.data.targetUrl);
			});
		};

		const processMicrosoftLogin = () => {
			$api.auth
				.microsoftRedirect(course.value.urlCode)
				.then(function (response) {
					window.location.replace(response.data.targetUrl);
				});
		};

		const resetEmail = () => {
			isEmailTested.value = false;
			form.value.setFieldValue("email", "");
		};

		if (
			[
				"auth.login.express.course",
				"auth.login.express.course.cnamed",
			].includes(route.name)
		) {
			componentDoneLoading.value = false;
		}
		onMounted(() => {
			faModal.value = new Modal(document.getElementById("2faModal"));
			if (
				[
					"auth.login.express.course",
					"auth.login.express.course.cnamed",
				].includes(route.name)
			) {
				form.value.setFieldValue("courseID", route.params.courseID);
				nextTick(function () {
					getCourse();
				});
			}
		});
		const updateCourse = (courseID) => {
			form.value.setFieldValue("courseID", courseID);
			nextTick(function () {
				getCourse();
			});
		};
		return {
			route,
			helpers,
			hasExistingAccount,
			isEmailTested,
			faModal,
			form,
			processBackButton,
			formOptions,
			course,
			componentDoneLoading,
			getCourse,
			login,
			register,
			loginFromSelfSignIn,
			verifyCourseEmail,
			verifyCourseLogin,
			login2FA,
			resend2FA,
			processGoogleLogin,
			processMicrosoftLogin,
			canSendChangePassword,
			processCanSendChangePassword,
			otpResent,
			otpTimeout,
			resetEmail,
			store,
			updateCourse,
			sections,
			step,
			credentials,
		};
	},
};
</script>
<style lang="scss" scoped>
// .course-info {
// 	h2,
// 	h3 {
// 		font-weight: bold;
// 	}

// 	h2 {
// 		// font-size: 20px;
// 		// line-height: 28px;
// 		font-size: 24px;
// 		line-height: 1.2;
// 		margin-bottom: 8px;
// 	}

// 	h3 {
// 		font-size: 12px;
// 	}

// 	p {
// 		font-size: 14px;
// 		margin-bottom: 8px;
// 	}

// 	& ~ a {
// 		font-weight: normal;
// 		color: #007aff;
// 		font-size: 12px;
// 	}

// 	.course-info-collapse {
// 		label {
// 			font-size: 12px;
// 		}
// 	}
// }
.contentBody {
	height: calc(100% - 134px);
}

.contentBody-danger {
	height: calc(100% - 134px - 44px);
}
</style>
